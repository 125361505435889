import React, { useState, useEffect, memo } from "react";
import AmeWagmi from "../../libs/AmeWagmi";
import { parseVibe, getShortAddress, parseProfile } from "../../libs/Utils";
import { format } from "date-fns";
import { isAddress } from "viem";
import { useNavigate } from "react-router-dom";
import { useUserVibeList } from "../../libs/Store";
import Avatar from "boring-avatars";
import Quote from "../Quote/Quote";
import Linkify from "linkify-react";

function UserVibeList(props) {
  const navigate = useNavigate();

  const linkifyOptions = {
    defaultProtocol: "https",
    target: "_blank",
    attributes: {
      onClick: (event) => {
        event.stopPropagation();
      },
    },
  };

  const [vibeWagmi, setVibeWagmi] = useState();
  const [profileWagmi, setProfileWagmi] = useState();

  const { vibes, setVibes, pages, setPages, vibeListType, setVibeListType } =
    useUserVibeList();

  useEffect(() => {
    if (props.config != undefined && props.type != undefined) {
      (async function fetchData() {
        const vibeWagmi = new AmeWagmi(
          props.config,
          props.config.chains[0].contracts.VibeComponent
        );
        const profileWagmi = new AmeWagmi(
          props.config,
          props.config.chains[0].contracts.VibeProfile
        );

        setVibeWagmi(vibeWagmi);
        setProfileWagmi(profileWagmi);

        setVibeListType(props.type);

        const storeVibes = useUserVibeList.getState().vibes;

        if (storeVibes.length != 0 && props.type == vibeListType) {
          setVibes(storeVibes);
        } else {
          console.log("pages", pages);
          getVibes(vibeWagmi, profileWagmi, props.type, 1, pages.pageSize);
        }
      })();
    }
  }, [props.type]);

  const loadMore = async () => {
    var newPageNum = pages.pageNum + 1;

    console.log("newPageNum", newPageNum);

    await getVibes(
      vibeWagmi,
      profileWagmi,
      vibeListType,
      newPageNum,
      pages.pageSize
    );

    setPages({
      pageNum: newPageNum,
    });
  };

  const getVibes = async (
    vibeWagmi,
    profileWagmi,
    vibeType,
    pageNum,
    pageSize
  ) => {
    console.log("vibeType, pageNum, pageSize", vibeType, pageNum, pageSize);

    var encode = vibeWagmi.encodeRequestParams(
      [
        { name: "address", type: "address" },
        { name: "pageNum", type: "uint256" },
        { name: "pageSize", type: "uint256" },
      ],
      [vibeType, pageNum, pageSize]
    );

    var result = await vibeWagmi.sendGetRequest("getVibesByAddress", encode);

    var decodeVibes = vibeWagmi.decodeResponseData(
      [{ name: "vibeList", type: "string[]" }],
      result
    );

    var parsedVibes = parseVibe(decodeVibes[0]);

    //query userinfo

    const users = parsedVibes.map((item) => item.user);

    var encodeProfileReq = profileWagmi.encodeRequestParams(
      [{ name: "category", type: "address[]" }],
      [users]
    );

    var profileResult = await profileWagmi.sendGetRequest(
      "getUserInfoByAddresses",
      encodeProfileReq
    );
    var decodeProfile = profileWagmi.decodeResponseData(
      [
        { name: "handle", type: "string[]" },
        { name: "nickname", type: "string[]" },
        { name: "avatar", type: "string[]" },
        { name: "profile", type: "string[]" },
      ],
      profileResult
    );

    var parsedProfile = parseProfile(decodeProfile);

    const newVibes = parsedVibes.map((item, index) => {
      return { ...item, ...parsedProfile[index] };
    });

    if (pageNum == 1) {
      setVibes(newVibes);
    } else {
      setVibes([...vibes, ...newVibes]);
    }

    if (newVibes.length < pageSize) {
      setPages({
        showLoadMore: false,
      });
    } else {
      setPages({
        showLoadMore: true,
      });
    }

    setPages({
      pageNum: pageNum,
    });
  };

  return vibes.length != 0 ? (
    <div className="VibeList">
      {vibes.map((item, index) => (
        <div className="VibeItem" key={index}>
          <div className="VibeItemTop">
            <div
              className="VibeItemUserInfo"
              onClick={() => {


                // navigate("/" + (item.handle != "" ? item.handle : item.user));


              }}
            >
              {item.avatar != "" ? (
                <div className="VibeItemAvatar">
                  <img src={item.avatar} width="100%" />
                </div>
              ) : (
                <Avatar name={item.user} variant="beam" size={42} />
              )}

              <div>
                {item.nickname != "" ? (
                  <div className="VibeItemNickname">{item.nickname}</div>
                ) : (
                  <div className="VibeItemAddress">
                    {getShortAddress(item.user)}
                  </div>
                )}

                {item.handle != "" ? (
                  <div className="VibeItemHandle">@{item.handle}</div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            <div className="VibeItemDate">{item.date}</div>
          </div>
          <div
            onClick={() => {
              navigate("/vibe/" + item.id);
            }}
            className="VibeEventArea"
          >
            <div className="VibeItemDetail">
              <Linkify as="p" options={linkifyOptions}>
                {item.content}
              </Linkify>
            </div>

            {item.resources.hasOwnProperty("images") ? (
              <div className="VibeItemImage">
                <img src={item.resources.images[0]} width="70%" />
              </div>
            ) : (
              <div></div>
            )}
          </div>
          {item.resources.hasOwnProperty("quote") ? (
            <div
              className="VibeItemQuote"
              onClick={() => {
                navigate("/vibe/" + item.resources.quote.id);
              }}
            >
              <Quote shortQuote={item.resources.quote.content}></Quote>
            </div>
          ) : (
            <div></div>
          )}

          {item.categories.length != 0 ? (
            <div className="VibeItemTags">
              {item.categories.map((item, index) => (
                <div
                  className="VibeCategory"
                  key={index}
                  onClick={() => {
                    navigate("/tag/" + item);
                    window.scrollTo({
                      top: 0,
                      behavior: "instant",
                    });
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ))}

      {pages.showLoadMore ? (
        <div className="VibeLoadMoreButton" onClick={loadMore}>
          Load More
        </div>
      ) : (
        <div></div>
      )}
    </div>
  ) : (
    <div className="VibeListNoData">No Data</div>
  );
}

export default UserVibeList;
