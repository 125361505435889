import { http, createConfig } from "@wagmi/core";
import { base } from "@wagmi/core/chains";

import { connectorsForWallets,getDefaultConfig } from "@rainbow-me/rainbowkit";
import {
  rainbowWallet,
  walletConnectWallet,
  metaMaskWallet,
  coinbaseWallet,
  injectedWallet
} from "@rainbow-me/rainbowkit/wallets";


// coinbaseWallet.preference = 'smartWalletOnly';




const localhost = {
  id: 31337,
  name: "Localhost",
  iconUrl: "",
  iconBackground: "#fff",
  nativeCurrency: { name: "Ethereum", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    default: { http: ["http://127.0.0.1:8545"] },
  },
  blockExplorers: {
    default: { name: "LocalScan", url: "http://127.0.0.1:8545" },
  },
  contracts: {
    VibeComponent: "0x49B8E3b089d4ebf9F37B1dA9B839Ec013C2cD8c9",
    VibeComment: "0xD18E3F31bD50B5c6e4cC740CB9Ca637F6eCC2944",
    VibeProfile: "0x3a601D5e56502B7F36450bf3EC3dAC59f23E8a7C",
  },
  greenfiledServer: "http://localhost:3011/",
};
const baseTestnet = {
  id: 84532,
  name: "Base Sepolia",
  iconUrl: "",
  iconBackground: "#fff",
  nativeCurrency: { name: "Ethereum", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://sepolia.base.org"] },
  },
  blockExplorers: {
    default: {
      name: "blockscout",
      url: "https://base-sepolia.blockscout.com/",
    },
  },
  contracts: {
    VibeComponent: "0x5Eb96B2A4f9684FB60159A14D9Be551848E7d8Ee",
    VibeComment: "0xE3593b1DF7123FbB900d7de4632812873878b869",
    VibeProfile: "0x9e32D6d62e038FFdd4a8C4F2476a3D70f76D376E",
  },
  greenfiledServer: "http://localhost:3011/",
};

const baseMainnet = {
  id: 8453,
  name: "Base",
  iconUrl: "",
  iconBackground: "#fff",
  nativeCurrency: { name: "Ethereum", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://mainnet.base.org"] },
  },
  blockExplorers: {
    default: {
      name: "basescan",
      url: "https://basescan.org",
    },
  },
  contracts: {
    VibeComponent: "0x0E2b5cF475D1BAe57C6C41BbDDD3D99ae6Ea59c7",
    VibeComment: "0x6c05611d174644E65c12E0Bd2f8848E05e3a2310",
    VibeProfile: "0x7F620691DDD1386F9e7a2eaF54a0Eebd30d81C4b",
  },
  greenfiledServer: "https://greenfield-file-server-8e623.ondigitalocean.app/",
};

base.contracts={
  VibeComponent: "0x0E2b5cF475D1BAe57C6C41BbDDD3D99ae6Ea59c7",
  VibeComment: "0x6c05611d174644E65c12E0Bd2f8848E05e3a2310",
  VibeProfile: "0x7F620691DDD1386F9e7a2eaF54a0Eebd30d81C4b",
}


const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [rainbowWallet, walletConnectWallet, metaMaskWallet,coinbaseWallet],
    },
  ],
  {
    appName: "VibeBoard",
    projectId: "dd8e0de6a8b743a1d7f9fb7aa6fdd07f",
  }
);

const config = getDefaultConfig({
  appName: 'My RainbowKit App',
  projectId: 'YOUR_PROJECT_ID',
  chains: [baseMainnet],
  transports: {
    [baseMainnet.id]: http(""),

  },
});

// const config = createConfig({
//   connectors: connectors,
//   chains: [base],
//   transports: {
//     [base.id]: http(""),
//   },
// });

export default config;
