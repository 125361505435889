import {
  readContract,
  writeContract,
  waitForTransactionReceipt,
} from "@wagmi/core";
import abi from "./AmeComponentABI.json";
import { encodeAbiParameters, decodeAbiParameters, parseEther } from "viem";

class AmeWagmi {
  constructor(_config, _contract) {
    this.config = _config;
    this.contract = _contract;
  }

  async sendGetRequest(_methodName, _params) {
    const result = await readContract(this.config, {
      abi,
      address: this.contract,
      functionName: "get",
      args: [_methodName, _params],
    });

    return result;
  }

  async sendPostAndPutRequest(
    _methodType,
    _methodName,
    _requestParams,
    _from,
    _value
  ) {
    const  txhash = await writeContract(this.config, {
      abi,
      address: this.contract,
      functionName: _methodType,
      args: [_methodName, _requestParams],
      account: _from,
      value: parseEther(_value),
    });

    const transactionReceipt = await waitForTransactionReceipt(this.config, {
      hash: txhash,
    });

    return transactionReceipt;
  }

  //Encode request parameters
  encodeRequestParams(_methodRequestParamsType, _requestParamValue) {
    return encodeAbiParameters(_methodRequestParamsType, _requestParamValue);
  }

  //Decode response data
  decodeResponseData(_methodResponseType, _resDataEncode) {
    return decodeAbiParameters(_methodResponseType, _resDataEncode);
  }
}

export default AmeWagmi;
