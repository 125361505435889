import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@rainbow-me/rainbowkit/styles.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./router";
import reportWebVitals from "./reportWebVitals";
import LeftBar from "./components/LeftBar/LeftBar";
import config from "./config/NetworkConfig";
import MobileHeader from "./components/MoblieHeader/MobileHeader";
import BaseLogo from "./assets/BaseLogo.png"

import {
  getDefaultConfig,
  RainbowKitProvider,
  darkTheme,
  connectorsForWallets
} from "@rainbow-me/rainbowkit";
import {
  metaMaskWallet
} from '@rainbow-me/rainbowkit/wallets';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
} from 'wagmi/chains';
import { WagmiProvider } from "wagmi";
import Avatar from "boring-avatars";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as buffer from "buffer";
window.Buffer = buffer.Buffer;
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));


// const connectors = connectorsForWallets(
//   [
//     {
//       groupName: 'Recommended',
//       wallets: [metaMaskWallet],
//     },
//   ],
//   {
//     appName: 'My RainbowKit App',
//     projectId: 'YOUR_PROJECT_ID',
//   }
// );

// const config = getDefaultConfig(connectors,NetworkConfig);


// const config = getDefaultConfig({
//   appName: 'My RainbowKit App',
//   projectId: 'YOUR_PROJECT_ID',
//   chains: [mainnet, polygon, optimism, arbitrum, base],
//   ssr: true, // If your dApp uses server side rendering (SSR)
// },NetworkConfig);

const CustomAvatar = ({ address, ensImage, size }) => {
  return <img src={BaseLogo} width="100%"/>
  // <Avatar name={address} variant="beam" size={88} />;
};






root.render(

  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider
        modalSize="compact"
        avatar={CustomAvatar}
        locale="en-US"
        theme={darkTheme({
          accentColor: "#4348C6",
          accentColorForeground: "white",
          borderRadius: "small",
        })}
      >
        <Router>
          <div className="App">
            <div className="AppLeft">
              <LeftBar></LeftBar>
            </div>
            <div className="AppMoblieHeader">
              <MobileHeader></MobileHeader>
            </div>
            <div className="AppRight">
              <div className="MoblieSplit"></div>
              <Routes>
                {routes.map((item, index) => (
                  <Route key={index} path={item.path} element={item.element} />
                ))}
              </Routes>
            </div>
          </div>
        </Router>
      </RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
