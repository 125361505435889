import { useState, useEffect } from "react";
import "./Comment.css";
import { getChainId, getChains, getAccount } from "@wagmi/core";
import AmeWagmi from "../../libs/AmeWagmi";
import { Input, ConfigProvider, Modal } from "antd";
import Emoji from "../Emoji/Emoji";
import LoadingIcon from "../../assets/Loading.svg";
function Comment(props) {
  const [commentContent, setCommentContent] = useState("");
  const [showCommentLoading, setShowCommentLoading] = useState(false);
  const sendComment = async () => {
    setShowCommentLoading(true);
    const account = getAccount(props.config);

    var ameWagmi = new AmeWagmi(
      props.config,
      props.config.chains[0].contracts.VibeComment
    );

    var encode = ameWagmi.encodeRequestParams(
      [
        { name: "a", type: "uint256" },
        { name: "b", type: "string" },
        { name: "c", type: "string" },
      ],
      [props.vibeId, commentContent, ""]
    );

    try {
      var result = await ameWagmi.sendPostAndPutRequest(
        "post",
        "postComment",
        encode,
        account.address,
        "0"
      );

      setShowCommentLoading(false);

      props.updateComments();
    } catch (error) {}
  };

  const emojiChange = (e) => {
    setCommentContent(commentContent + e.native);
  };

  return showCommentLoading ? (
    <div className="CommentLoading">
      <img src={LoadingIcon} width={64} />
    </div>
  ) : (
    <div className="Comment">
      <ConfigProvider
        theme={{
          components: {
            Select: {
              colorText: "#fff",
              optionSelectedColor: "#0F1112",
              optionSelectedBg: "#fff",
              optionActiveBg: "#474D50",
            },
          },
          token: {
            // colorPrimary: "#fff",
            colorTextPlaceholder: "#959798",
            borderRadius: 4,
            colorBgElevated: "#17191A",
            colorBgContainer: "#0F1112",
            colorBorder: "#202225",
            lineWidth: "1px",
          },
        }}
      >
        <Input.TextArea
          showCount
          maxLength={200}
          className="CommentInputBg"
          value={commentContent}
          onChange={(e) => setCommentContent(e.target.value)}
          placeholder="Add your comment here..."
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </ConfigProvider>
      <div className="CommentButtonGroup">
        <Emoji emojiChange={emojiChange}></Emoji>
        <div className="CommentButton" onClick={sendComment}>
          Comment
        </div>
      </div>
    </div>
  );
}

export default Comment;
