import React, { useState, useEffect, memo } from "react";
import "./VibeList.css";
import AmeWagmi from "../../libs/AmeWagmi";
import { parseVibe, getShortAddress, parseProfile } from "../../libs/Utils";
import { isAddress } from "viem";
import { useNavigate } from "react-router-dom";
import { useVibeList, useUserVibeList } from "../../libs/Store";
import Avatar from "boring-avatars";
import Quote from "../Quote/Quote";
import Linkify from "linkify-react";
import LoadingIcon from "../../assets/Loading.svg";
function VibeList(props) {
  const navigate = useNavigate();

  const linkifyOptions = {
    defaultProtocol: "https",
    target: "_blank",
    attributes: {
      onClick: (event) => {
        event.stopPropagation();
      },
    },
  };

  const [vibeWagmi, setVibeWagmi] = useState();
  const [profileWagmi, setProfileWagmi] = useState();
  const [showLoading, setShowLoading] = useState(true);

  const { reset } = useUserVibeList();
  const { vibes, setVibes, pages, setPages, vibeListType, setVibeListType } =
    useVibeList();

  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
 
    if (props.config != undefined) {
      (async function fetchData() {
        const vibeWagmi = new AmeWagmi(
          props.config,
          props.config.chains[0].contracts.VibeComponent
        );
        const profileWagmi = new AmeWagmi(
          props.config,
          props.config.chains[0].contracts.VibeProfile
        );

        setVibeWagmi(vibeWagmi);
        setProfileWagmi(profileWagmi);

        setVibeListType(props.type);

        const storeVibes = useVibeList.getState().vibes;

        if (props.trigger != trigger) {
          await getVibes(
            vibeWagmi,
            profileWagmi,
            props.type,
            1,
            pages.pageSize
          );
          console.log("GETVibe");
        } else {
          if (storeVibes.length != 0 && props.type == vibeListType) {
            setVibes(storeVibes);

            setShowLoading(false);
          } else {
            console.log("GETVibe");
            await getVibes(
              vibeWagmi,
              profileWagmi,
              props.type,
              1,
              pages.pageSize
            );
          }
        }

        setTrigger(props.trigger);
      })();
    }

  }, [props.config, props.type, props.trigger]);

  const loadMore = async () => {

    var newPageNum = pages.pageNum + 1;
    setPages({
      pageNum: newPageNum,
    });

    await getVibes(
      vibeWagmi,
      profileWagmi,
      vibeListType,
      newPageNum,
      pages.pageSize
    );

  };

  const getVibes = async (
    vibeWagmi,
    profileWagmi,
    vibeType,
    pageNum,
    pageSize
  ) => {
    setShowLoading(true)
    var result;
    if (vibeType == "All") {
      var encode = vibeWagmi.encodeRequestParams(
        [
          { name: "pageNum", type: "uint256" },
          { name: "pageSize", type: "uint256" },
        ],
        [pageNum, pageSize]
      );

      result = await vibeWagmi.sendGetRequest("getVibes", encode);
    } else if (vibeType == "Featured") {
      var encode = vibeWagmi.encodeRequestParams(
        [
          { name: "pageNum", type: "uint256" },
          { name: "pageSize", type: "uint256" },
        ],
        [pageNum, pageSize]
      );

      result = await vibeWagmi.sendGetRequest("getVibesByFeatured", encode);
    } else if (isAddress(vibeType)) {
      var encode = vibeWagmi.encodeRequestParams(
        [
          { name: "address", type: "address" },
          { name: "pageNum", type: "uint256" },
          { name: "pageSize", type: "uint256" },
        ],
        [vibeType, pageNum, pageSize]
      );

      result = await vibeWagmi.sendGetRequest("getVibesByAddress", encode);
    } else {
      var encode = vibeWagmi.encodeRequestParams(
        [
          { name: "category", type: "string" },
          { name: "pageNum", type: "uint256" },
          { name: "pageSize", type: "uint256" },
        ],
        [vibeType, pageNum, pageSize]
      );

      result = await vibeWagmi.sendGetRequest("getVibesByCategory", encode);
    }

    var decodeVibes = vibeWagmi.decodeResponseData(
      [{ name: "vibeList", type: "string[]" }],
      result
    );

    var parsedVibes = parseVibe(decodeVibes[0]);

    //query userinfo

    const users = parsedVibes.map((item) => item.user);

    var encodeProfileReq = profileWagmi.encodeRequestParams(
      [{ name: "category", type: "address[]" }],
      [users]
    );

    var profileResult = await profileWagmi.sendGetRequest(
      "getUserInfoByAddresses",
      encodeProfileReq
    );
    var decodeProfile = profileWagmi.decodeResponseData(
      [
        { name: "handle", type: "string[]" },
        { name: "nickname", type: "string[]" },
        { name: "avatar", type: "string[]" },
        { name: "profile", type: "string[]" },
      ],
      profileResult
    );

    var parsedProfile = parseProfile(decodeProfile);

    const newVibes = parsedVibes.map((item, index) => {
      return { ...item, ...parsedProfile[index] };
    });

    if (pageNum == 1) {
      setVibes(newVibes);
    } else {
      setVibes([...vibes, ...newVibes]);
    }

    if (newVibes.length < pageSize) {
      setPages({
        showLoadMore: false,
      });
    } else {
      setPages({
        showLoadMore: true,
      });
    }

    setPages({
      pageNum: pageNum,
    });

    setShowLoading(false)
  };

  return vibes.length != 0 ? (
    <div className="VibeList">
      {vibes.map((item, index) => (
        <div className="VibeItem" key={index}>
          <div className="VibeItemTop">
            <div
              className="VibeItemUserInfo"
              onClick={() => {
                reset();
                navigate("/" + (item.handle != "" ? item.handle : item.user));
              }}
            >
              {item.avatar != "" ? (
                <div className="VibeItemAvatar">
                  <img src={item.avatar} width="100%" />
                </div>
              ) : (
                <Avatar name={item.user} variant="beam" size={42} />
              )}

              <div>
                {item.nickname != "" ? (
                  <div className="VibeItemNickname">{item.nickname}</div>
                ) : (
                  <div className="VibeItemAddress">
                    {getShortAddress(item.user)}
                  </div>
                )}

                {item.handle != "" ? (
                  <div className="VibeItemHandle">@{item.handle}</div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            <div className="VibeItemDate">{item.date}</div>
          </div>
          <div
            onClick={() => {
              navigate("/vibe/" + item.id);
            }}
            className="VibeEventArea"
          >
            <div className="VibeItemDetail">
              <Linkify as="p" options={linkifyOptions}>
                {item.content}
              </Linkify>
            </div>

            {item.resources.hasOwnProperty("images") ? (
              <div className="VibeItemImage">
                <img src={item.resources.images[0]} width="70%" />
              </div>
            ) : (
              <div></div>
            )}
          </div>
          {item.resources.hasOwnProperty("quote") ? (
            <div
              className="VibeItemQuote"
              onClick={() => {
                navigate("/vibe/" + item.resources.quote.id);
              }}
            >
              <Quote shortQuote={item.resources.quote.content}></Quote>
            </div>
          ) : (
            <div></div>
          )}

          {item.categories.length != 0 ? (
            <div className="VibeItemTags">
              {item.categories.map((item, index) => (
                <div
                  className="VibeCategory"
                  key={index}
                  onClick={() => {
                    navigate("/tag/" + item);
                    window.scrollTo({
                      top: 0,
                      behavior: "instant",
                    });
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ))}

      {pages.showLoadMore ? (
        <div className="VibeLoadMore">
          {showLoading ? (
            <div className="ShowVibeListLoading">
              <img src={LoadingIcon} width={32} />
            </div>
          ) : (
            <div className="VibeLoadMoreButton" onClick={loadMore}>
              Load More
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  ) : (
    <div>
      {showLoading ? (
        <div className="ShowVibeListLoading">
          <img src={LoadingIcon} width={32} />
        </div>
      ) : (
        <div className="VibeListNoData">No Data</div>
      )}
    </div>
  );
}

export default VibeList;
