import { useState, useEffect } from "react";
import "./LeftBar.css";

import Logo from "../../assets/logo.svg";
import BNBGreenFieldLogo from "../../assets/BNBGreenFieldLogo.svg"
import BaseSupported from "../../assets/BaseSupported.svg"
import AmeLogo from "../../assets/AmeLogo.png";
import githubIcon from "../../assets/github.svg";
import websiteIcon from "../../assets/website.svg";
import twitterIcon from "../../assets/twitter.svg";
import Navigator from "../Navigator/Navigator";
import { ConnectButton } from "@rainbow-me/rainbowkit";
function LeftBar(props) {
  return (
    <div className="LeftBar">
      <div className="LeftBarTop">
        <div className="Logo">
          <img src={Logo} width={140} />
          <p className="Slogan">Fully onchain community</p>
        </div>
        <ConnectButton
          chainStatus="name"
          showBalance={{
            smallScreen: false,
            largeScreen: false,
          }}
          label="Connect Wallet"
        />
        <Navigator></Navigator>
      </div>



      <div className="LeftBarBottom">
        {/* <ul className="SocialLink">
          <li>
            <a href="https://x.com/0xAmeNetwork" target="_blank">
              <img src={twitterIcon} width={20} />
            </a>
          </li>
          <li>
            <a href="https://github.com/HelloRickey/ame" target="_blank">
              <img src={githubIcon} width={20} />
            </a>
          </li>
          <li>
            <a href="https://ame.network/" target="_blank">
              <img src={websiteIcon} width={20} />
            </a>
          </li>
        </ul>
        <div className="Copyright">© 2024 Ame Network.</div> */}
        <div className="PoweredBy">
          <a href="https://www.base.org/" target="_blank">
            <p>Built on</p>
            <img src={BaseSupported} width={45} />
          </a>
        </div>
        <div className="PoweredBy">
          <a href="https://ame.network/" target="_blank">
            <p>Powered by</p>
            <img src={AmeLogo} width={100} />
          </a>
        </div>

        <div className="PoweredBy">
          <a href="https://greenfield.bnbchain.org/en" target="_blank">
            <p>Storage provided by</p>
            <img src={BNBGreenFieldLogo} width={110} />
          </a>
        </div>


        
      </div>
    </div>
  );
}
export default LeftBar;
