import { useState, useEffect } from "react";
import "./ProfileEditor.scss";
import { Select, ConfigProvider, Modal, Input } from "antd";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import Tags from "../../components/Tags/Tags";
import ImageDelete from "../../assets/DeleteImage.svg";
import BackIcon from "../../assets/BackIcon.svg";
import { useNavigate } from "react-router-dom";
import AmeWagmi from "../../libs/AmeWagmi";
import config from "../../config/NetworkConfig";
import { parseProfile } from "../../libs/Utils";
import { getAccount } from "@wagmi/core";
import toast, { Toaster } from "react-hot-toast";
function ProfileEditor() {
  const navigate = useNavigate();

  const [bio, setBio] = useState("");
  const [tags, setTags] = useState([]);
  const [handle, setHandle] = useState("");
  const [nickname, setNickname] = useState("");
  const [handleError, setHandleError] = useState(false);
  const [file, setFile] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const [profile, setProfile] = useState();
  const [currentAddress, setCurrentAddress] = useState();
  const [isRegistered, setIsRegistered] = useState(false);
  const [ameWagmi, setAmeWagmi] = useState();
  useEffect(() => {
    (async function fetchData() {
      const ameWagmi = new AmeWagmi(
        config,
        config.chains[0].contracts.VibeProfile
      );

      const account = getAccount(config);
      setCurrentAddress(account.address);
      setAmeWagmi(ameWagmi);

      await getUserProfile(ameWagmi, account.address);
    })();
  }, []);

  const getUserProfile = async (ameWagmi, userAddress) => {
    var encode = ameWagmi.encodeRequestParams(
      [{ name: "address", type: "address[]" }],
      [[userAddress]]
    );

    var returnValue = await ameWagmi.sendGetRequest(
      "getUserInfoByAddresses",
      encode
    );
    var decode = ameWagmi.decodeResponseData(
      [
        { name: "handle", type: "string[]" },
        { name: "nickname", type: "string[]" },
        { name: "avatar", type: "string[]" },
        { name: "profile", type: "string[]" },
      ],
      returnValue
    );

    var decodeProfile = parseProfile(decode)[0];

    setProfile(decodeProfile);
    setTags(decodeProfile.profile == "" ? [] : decodeProfile.profile.tags);
    setNickname(decodeProfile.nickname);
    setBio(decodeProfile.profile.bio);
    setImageBase64(decodeProfile.avatar);
    setIsRegistered(decodeProfile.handle == "" ? false : true);
  };

  const changeTags = (tags) => {
    setTags(tags);
  };

  const changeHandle = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-z0-9_]*$/;
    setHandle(inputValue);
    if (regex.test(inputValue) || inputValue === "") {
      setHandleError(false);
    } else {
      setHandleError(true);
    }
  };

  const changeImageFile = async (file) => {
    setFile(file);
  };

  const changeImageBase64 = async (imageBase64) => {
    setImageBase64(imageBase64);
  };

  const saveProfile = async () => {
    var profileJSON = {
      bio: bio,
      tags: tags,
    };

    if (isRegistered) {
      toast.promise(
        (async function fetchData() {
          var imageURL = await uploadImage();

          await updateUserProfile(
            currentAddress,
            nickname,
            imageURL,
            profileJSON
          );
        })(),

        {
          loading: "Loading",
          success: "Success",
          error: "Error",
        }
      );
    } else {
      if (handle == "") {
        setHandleError(true);
      } else {
        const toastId = toast.loading("Loading...");
        var handleValid = await checkUserHandler(handle);
        if (handleValid) {
          try {
            var imageURL = await uploadImage();

            await createUser(
              currentAddress,
              handle,
              nickname,
              imageURL,
              profileJSON
            );
            toast.success("Success", {
              id: toastId,
              duration: 1500,
            });
          } catch (error) {
            toast.error("Error", {
              id: toastId,
              duration: 1500,
            });
          }
        } else {
          toast.error(
            "The handle has been registered, please select another one.",
            {
              id: toastId,
              duration: 1500,
            }
          );
        }
      }
    }
  };

  const createUser = async (_user, _handle, _nickname, _avatar, _profile) => {
    var encode = ameWagmi.encodeRequestParams(
      [
        { name: "handle", type: "string" },
        { name: "nickname", type: "string" },
        { name: "avatar", type: "string" },
        { name: "profile", type: "string" },
      ],
      [_handle, _nickname, _avatar, JSON.stringify(_profile)]
    );

    var result = await ameWagmi.sendPostAndPutRequest(
      "post",
      "createUser",
      encode,
      _user,
      "0"
    );
  };

  const checkUserHandler = async (handle) => {
    var encode = ameWagmi.encodeRequestParams(
      [{ name: "handle", type: "string[]" }],
      [[handle]]
    );

    var returnValue = await ameWagmi.sendGetRequest(
      "getUserInfoByHandles",
      encode
    );

    var decode = ameWagmi.decodeResponseData(
      [
        { name: "address", type: "address[]" },
        { name: "nickname", type: "string[]" },
        { name: "avatar", type: "string[]" },
        { name: "profile", type: "string[]" },
      ],
      returnValue
    );

    if (decode[0][0] == "0x0000000000000000000000000000000000000000") {
      return true;
    } else {
      return false;
    }
  };

  const updateUserProfile = async (_user, _nickname, _avatar, _profile) => {
    var encode = ameWagmi.encodeRequestParams(
      [
        { name: "nickname", type: "string" },
        { name: "avatar", type: "string" },
        { name: "profile", type: "string" },
      ],
      [_nickname, _avatar, JSON.stringify(_profile)]
    );

    var result = await ameWagmi.sendPostAndPutRequest(
      "put",
      "updateUserInfo",
      encode,
      _user,
      "0"
    );
  };

  const uploadImage = async () => {
    var imageURL = "";
    if (file != "") {
      const formData = new FormData();
      formData.append("file", file, file.name);

      var response = await fetch(config.chains[0].greenfiledServer + "image", {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      imageURL = data.message;
    } else {
      imageURL = imageBase64;
    }
    return imageURL;
  };

  return (
    <div className="ProfileEditor">
      <Toaster />

      <div className="ProfileEditorHeader">
        <div
          className="ProfileEditorBackButton"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={BackIcon} width={24} />
          <p>Back</p>
        </div>
        <div className="ProfileEditorTitle">Edit Profile</div>
        <div className="ProfileEditorRight"></div>
      </div>
      {imageBase64 != "" ? (
        <div className="ProfileAvatar">
          <div
            className="ProfileAvatarDelete"
            onClick={() => {
              setFile("");
              setImageBase64("");
            }}
          >
            <img src={ImageDelete} width={32} />
          </div>
          <img src={imageBase64} width="100%" />
        </div>
      ) : (
        <ImageUpload
          changeImageFile={changeImageFile}
          changeImageBase64={changeImageBase64}
          avatar=""
          uploadType="avatar"
        ></ImageUpload>
      )}
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "#1D1D1D",
              headerBg: "#1D1D1D",
              titleColor: "#fff",
            },
            Input: {
              hoverBg: "#000",
              activeBg: "#000",
            },
          },
          token: {
            colorBgMask: "rgba(0, 0, 0, 0.7)",
            colorIcon: "#fff",
            colorIconHover: "#fff",
            colorTextPlaceholder: "#4B4B4B",
          },
        }}
      >
        {isRegistered ? (
          <div></div>
        ) : (
          <div>
            <Input
              placeholder="Your Handle"
              status={handleError ? "error" : ""}
              value={handle}
              onChange={changeHandle}
              className="VibeInputBg"
              showCount
              maxLength={20}
            />
            <div className={handleError ? "HandleTipError" : "HandleTipNormal"}>
              Please enter lowercase letters, numbers and underscores
            </div>
          </div>
        )}

        <Input
          placeholder="Your Nickname"
          className="VibeInputBg"
          showCount
          maxLength={20}
          value={nickname}
          onChange={(e) => {
            setNickname(e.target.value);
          }}
        />

        <Input.TextArea
          showCount
          maxLength={300}
          className="VibeInputBg"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          placeholder="Your Bio..."
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </ConfigProvider>
      <Tags changeTags={changeTags} myTags={tags} edit={true} max={3}></Tags>

      <div className="ProfileEditorSaveButton" onClick={saveProfile}>
        Save
      </div>
    </div>
  );
}

export default ProfileEditor;
