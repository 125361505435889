import { useState, useEffect, memo } from "react";
import "./NewVibe.css";
import { Select, ConfigProvider, Modal, Input, Spin } from "antd";

import { DownOutlined } from "@ant-design/icons";
import AmeWagmi from "../../libs/AmeWagmi";
import { getChainId, getChains, getAccount } from "@wagmi/core";
import ImageUpload from "../ImageUpload/ImageUpload";
import VibeCategory from "../../config/VibeCategory.json";
import Selected from "../../assets/Selected.svg";
import Quote from "../Quote/Quote";
import ImageDelete from "../../assets/DeleteImage.svg";
import toast, { Toaster } from "react-hot-toast";
import LoadingIcon from "../../assets/Loading.svg";
import Emoji from "../Emoji/Emoji";
function NewVibe(props) {
  const [newVibeCategoryValue, setNewVibeCategoryValue] = useState([]);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState();
  const [file, setFile] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const [config, setConfig] = useState();
  const [quote, setQuote] = useState("");
  const [showNewVibeLoading, setShowNewVibeLoading] = useState(false);
  const [handleError, setHandleError] = useState(false);
  useEffect(() => {
    if (props.resources != undefined) {
      setQuote(props.resources);
    }
  }, [props.resources]);

  var changeNewVibeCategory = async (value) => {
    setNewVibeCategoryValue(value);
  };

  const handleNewVibe = async () => {
    if (description == "") {
      setHandleError(true);
    } else {
      setShowNewVibeLoading(true);
      //upload image
      var imageURL = "";
      if (file != "") {
        const formData = new FormData();
        formData.append("file", file, file.name);

        var response = await fetch(
          props.config.chains[0].greenfiledServer + "image",
          {
            method: "POST",
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        imageURL = data.message;
      }

      //create json

      var resources = {};

      if (imageURL != "") {
        resources.images = [];
        resources.images.push(imageURL);
      }

      if (quote != "") {
        resources.quote = quote;
      }

      var jsonStr = JSON.stringify(resources);

      const account = getAccount(props.config);

      var ameWagmi = new AmeWagmi(
        props.config,
        props.config.chains[0].contracts.VibeComponent
      );

      var encode = ameWagmi.encodeRequestParams(
        [
          { name: "a", type: "string[]" },
          { name: "b", type: "string" },
          { name: "c", type: "string" },
          { name: "d", type: "address" },
        ],
        [newVibeCategoryValue, description, jsonStr, account.address]
      );

      try {
        var result = await ameWagmi.sendPostAndPutRequest(
          "post",
          "postVibe",
          encode,
          account.address,
          "0"
        );

        if (result.status == "success") {
          props.updateList();
        }
      } catch (error) {
        toast.error("Failed", {
          duration: 1200,
          position: "top-center",
        });
      }
    }
  };

  const changeImageFile = async (file) => {
    setFile(file);
  };

  const changeImageBase64 = async (imageBase64) => {
    setImageBase64(imageBase64);
  };

  const emojiChange = (e) => {
    setDescription(description + e.native);
  };
  return (
    <div>
      <Toaster />

      {showNewVibeLoading ? (
        <div className="NewVibeLoading">
          <img src={LoadingIcon} width={64} />
        </div>
      ) : (
        <div className="NewVibe">
          <Input.TextArea
            showCount
            maxLength={300}
            className="VibeInputBg"
            value={description}
            // status={handleError ? "error" : ""}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Write something..."
            autoSize={{ minRows: 3, maxRows: 5 }}
          />

          {/* {handleError ? (
            <div className="NewVibeErrorText">
              Please enter lowercase letters, numbers and underscores
            </div>
          ) : (
            <div></div>
          )} */}

          <ConfigProvider
            theme={{
              components: {
                Select: {
                  colorText: "#fff",
                  optionSelectedColor: "#0F1112",
                  optionSelectedBg: "#fff",
                  optionActiveBg: "#474D50",
                  multipleItemBg: "#fff",
                },
              },
              token: {
                // colorPrimary: "#fff",
                colorTextPlaceholder: "#fff",
                borderRadius: 4,
                colorBgElevated: "#17191A",
                colorBgContainer: "#0F1112",
                colorBorder: "#202225",
                lineWidth: "1px",
              },
            }}
          >
            <div className="NewVibeTags">
              <Select
                suffixIcon={
                  <div>
                    <span>
                      {newVibeCategoryValue.length} / {3} &nbsp;
                    </span>
                    <DownOutlined></DownOutlined>
                  </div>
                }
                menuItemSelectedIcon={
                  <div>
                    <img src={Selected} width={18} />
                  </div>
                }
                mode="multiple"
                maxCount={3}
                style={{ width: "100%" }}
                placeholder="Tags (optional)"
                options={VibeCategory}
                onChange={changeNewVibeCategory}
                className="Select"
              />
            </div>
          </ConfigProvider>

          {quote != "" ? (
            <Quote shortQuote={quote.content}></Quote>
          ) : (
            <div></div>
          )}

          <div className="NewVibeButtons">
            <div className="NewVibeFunctionsButton">
              <ImageUpload
                changeImageFile={changeImageFile}
                changeImageBase64={changeImageBase64}
                avatar=""
                uploadType="vibe"
              ></ImageUpload>
              <div className="NewVibeEmoji">
                <Emoji emojiChange={emojiChange}></Emoji>
              </div>
            </div>
            {description != "" ? (
              <div className="CreateVibeButton" onClick={handleNewVibe}>
                Post
              </div>
            ) : (
              <div className="CreateVibeButtonDisable">Post</div>
            )}
          </div>

          {imageBase64 != "" ? (
            <div className="NewVibeImage">
              <div
                className="NewVibeImageDelete"
                onClick={() => {
                  setFile("");
                  setImageBase64("");
                }}
              >
                <img src={ImageDelete} width={32} />
              </div>
              <img src={imageBase64} width="100%" />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
}

export default NewVibe;
