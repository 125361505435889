import { useState, useEffect } from "react";
import "./Vibe.css";
import { Select, ConfigProvider, Modal} from "antd";
import VibeCategory from "../../config/VibeCategory.json";
import { useConnectModal, useChainModal } from "@rainbow-me/rainbowkit";
import { getChainId, getChains, getAccount } from "@wagmi/core";
import { useNavigate } from "react-router-dom";
import config from "../../config/NetworkConfig";
import VibeList from "../VibeList/VibeList";
import AmeWagmi from "../../libs/AmeWagmi";
import NewVibe from "../NewVibe/NewVibe";
import { useVibeList } from "../../libs/Store";
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

function Vibe() {
  const navigate = useNavigate();
  const params = useParams();

  const { openConnectModal } = useConnectModal();
  const { openChainModal } = useChainModal();

  const [showVibeModal, setShowVibeModal] = useState(false);

  const [vibeCategoryOptions, setVibeCategoryOptions] = useState();

  const { vibeType, setVibeType, reset } = useVibeList();

  const [trigger, setTrigger] = useState(0);

  const [ameWagmi, setAmeWagmi] = useState();



  useEffect(() => {
    if (params.tag != undefined) {
      setVibeType(params.tag);
    } else {
      setVibeType("All");
    }

    const ameWagmi = new AmeWagmi(
      config,
      config.chains[0].contracts.VibeComponent
    );

    setAmeWagmi(ameWagmi);

    var vibeCategoryOptions = [
      {
        label: "All",
        value: "All",
      },
      // {
      //   label: "Featured",
      //   value: "Featured",
      // },
    ];

    vibeCategoryOptions = vibeCategoryOptions.concat(VibeCategory);

    setVibeCategoryOptions(vibeCategoryOptions);

    const storeVibeType = useVibeList.getState().vibeType;
    if (storeVibeType != "") {
      setVibeType(storeVibeType);
    }
  }, [params.tag]);

  var changeVibeCategory = async (value) => {
    // setVibeType(value);

    var navigateTo = value == "All" ? "/" : "/tag/" + value;
    navigate(navigateTo);
  };

  const getConnectState = () => {
    if (config != undefined) {
      const account = getAccount(config);
      if (account.address == undefined) {
        return 1;
      } else if (account.chain == undefined) {
        return 2;
      } else {
        return 3;
      }
    }
  };

  const updateList = () => {
    toast.success("Success", {
      duration: 1200,
      position: "top-center",
    });

    reset();

    setTrigger(trigger + 1);

    setShowVibeModal(false);

    navigate("/");
  };

  return (
    <div className="Vibe">

      <Toaster />
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "#1D1D1D",
              headerBg: "#1D1D1D",
              titleColor: "#fff",
            },
            Input: {
              hoverBg: "#000",
              activeBg: "#000",
            },
          },
          token: {
            colorBgMask: "rgba(0, 0, 0, 0.7)",
            colorIcon: "#fff",
            colorIconHover: "#fff",
            colorTextPlaceholder: "#4B4B4B",
          },
        }}
      >

        <Modal
          destroyOnClose={true}
          title="New Vibe"
          open={showVibeModal}
          maskClosable={false}
          footer={null}
          centered={true}
          onCancel={() => {
            setShowVibeModal(false);
          }}
        >
          <NewVibe config={config} updateList={updateList}></NewVibe>
        </Modal>
      </ConfigProvider>

      <div className="VibeHeaderFixedSplit"></div>

      <div className="VibeHeaderFixed">
        <div className="VibeHeader">
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  colorText: "#fff",
                  optionSelectedColor: "#0F1112",
                  optionSelectedBg: "#fff",
                  optionActiveBg: "#474D50",
                },
              },
              token: {
                // colorPrimary: "#fff",
                colorTextPlaceholder: "#fff",
                borderRadius: 4,
                colorBgElevated: "#17191A",
                colorBgContainer: "#0F1112",
                colorBorder: "#202225",
                lineWidth: "1px",
              },
            }}
          >
            <Select
              value={vibeType}
              options={vibeCategoryOptions}
              onChange={changeVibeCategory}
              className="Select"
            />
          </ConfigProvider>
          <div
            onClick={
              getConnectState() == 1
                ? openConnectModal
                : getConnectState() == 2
                ? openChainModal
                : () => {
                    setShowVibeModal(true);
                  }
            }
            className="NewVibeButton"
          >
            New Vibe
          </div>
        </div>
      </div>
  
      <VibeList config={config} type={vibeType} trigger={trigger}></VibeList>
    </div>
  );
}
export default Vibe;
