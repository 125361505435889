import { format } from "date-fns";
import { checksumAddress } from "viem";
const parseVibe = (vibesEncode) => {
  var vibes = [];

  if (vibesEncode.length == 0) {
    return vibes;
  } else {
    vibes = [];



    for (var item of vibesEncode) {

    

      var vibe = {
        id: "",
        categories: [],
        content: "",
        resources: "",
        user: "",
        date: "",
      };

      var itemArray = item.split(",");

      vibe.id = itemArray[0];


      if (item != "") {

        if (itemArray[1] != "") {
          for (var item of decodeBase64(itemArray[1]).split(",")) {
            vibe.categories.push(decodeBase64(item));
          }
        }

        vibe.content = decodeBase64(itemArray[2]);


        try {
          var sourceJSON = JSON.parse(decodeBase64(itemArray[3]));

          if (checkSourcesJSON(sourceJSON)) {
            vibe.resources = sourceJSON;
          } else {
            vibe.resources = "";
          }
        } catch (e) {
          vibe.resources = "";
        }
        vibe.user = checksumAddress(itemArray[4]);
        vibe.date = format(itemArray[5] * 1000, "MMM d");

        vibes.push(vibe);
      }
    }

    return vibes;
  }
};

var isBase64 = (str) => {
  const base64Pattern =
    /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=)?$/i;
  return base64Pattern.test(str);
};

var getShortAddress = (address) => {
  if (address != undefined) {
    if (address.length != undefined) {
      let end = address.length;
      let addressShort =
        address.substring(0, 5) + "..." + address.substring(end - 4, end);
      return addressShort;
    }
  }
};

function decodeBase64(base64) {
  const binaryString = atob(base64);

  const utf8String = decodeURIComponent(escape(binaryString));
  return utf8String;
}

function checkSourcesJSON(json) {
  var validKeys = ["images", "quote"];
  var isValid = true;
  for (const key in json) {
    if (!validKeys.includes(key)) {
      isValid = false;
      break;
    }

    if (key == "images") {
      if (!Array.isArray(json.images)) {
        isValid = false;
        break;
      }
    }
  }
  return isValid;
}

function parseComments(decodeComments) {
  var commentJSON = [];
  for (var item of decodeComments) {
    var commentArray = item.split(",");

    commentJSON.push({
      user: commentArray[4],
      comment: decodeBase64(commentArray[2]),
      date: format(commentArray[5] * 1000, "MMM d"),
    });
  }
  return commentJSON;
}

function parseProfile(profiles) {
  const keys = ["handle", "nickname", "avatar", "profile"];

  const result = profiles[0].map((_, index) => {
    const obj = {};
    keys.forEach((key, i) => {
      if (key == "profile") {
        if (profiles[i][index] != "") {
          obj[key] = JSON.parse(profiles[i][index]);
        } else {
          obj[key] = {
            bio: "",
            tags: [],
          };
        }
      } else {
        obj[key] = profiles[i][index];
      }
    });
    return obj;
  });

  return result;
}

export { getShortAddress, parseVibe, parseComments, parseProfile };
