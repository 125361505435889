import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Avatar from "boring-avatars";
import "./VibeDetail.css";
import { Input, ConfigProvider, Modal } from "antd";
import { getAccount } from "@wagmi/core";
import config from "../../config/NetworkConfig";
import AmeWagmi from "../../libs/AmeWagmi";
import { parseVibe, getShortAddress, parseProfile } from "../../libs/Utils";
import NewVibe from "../../components/NewVibe/NewVibe";
import QuoteIcon from "../../assets/QuoteIcon.svg";
import CommentIcon from "../../assets/CommentIcon.svg";
import { useConnectModal, useChainModal } from "@rainbow-me/rainbowkit";
import Comment from "../../components/Comment/Comment";
import Quote from "../../components/Quote/Quote";
import Linkify from "linkify-react";
import Comments from "../../components/Comments/Comments";
import BackIcon from "../../assets/BackIcon.svg";
import CopyLinkIcon from "../../assets/CopyLinkIcon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import LoadingIcon from "../../assets/Loading.svg";

function VibeDetail() {
  const params = useParams();
  const navigate = useNavigate();

  const { openConnectModal } = useConnectModal();
  const { openChainModal } = useChainModal();

  const [showModal, setShowModal] = useState(false);

  const [vibe, setVibe] = useState("");

  const [modalType, setModalType] = useState("Quote");

  const [quoteInfo, setQuoteInfo] = useState("");

  const [quoteDetail, setQuoteDetail] = useState("");

  const [ameWagmi, setAmeWagmi] = useState();

  const [trigger, setTrigger] = useState(0);

  const [showLoading, setShowLoading] = useState(true);

  const linkifyOptions = {
    defaultProtocol: "https",
    target: "_blank",
    attributes: {
      onClick: (event) => {
        event.stopPropagation();
      },
    },
  };

  useEffect(() => {
    (async function fetchData() {
      const ameWagmi = new AmeWagmi(
        config,
        config.chains[0].contracts.VibeComponent
      );

      const profileWagmi = new AmeWagmi(
        config,
        config.chains[0].contracts.VibeProfile
      );

      var vibeDetail = await getVibe(ameWagmi, params.vibeId);

      var userInfo = await getUserInfo(profileWagmi, vibeDetail.user);

      const newVibeDetail = { ...vibeDetail, ...userInfo };

      if (vibeDetail.resources.quote != undefined) {
        var quoteDetail = await getVibe(
          ameWagmi,
          vibeDetail.resources.quote.id
        );
        var quoteUserInfo = await getUserInfo(profileWagmi, quoteDetail.user);
        var newQuoteDetail = { ...quoteDetail, ...quoteUserInfo };
        setQuoteDetail(newQuoteDetail);
      }

      setVibe(newVibeDetail);

      console.log("newVibeDetail", newVibeDetail);




  

      setAmeWagmi(ameWagmi);
      setShowLoading(false);
    })();
  }, [params]);

  const getVibe = async (ameWagmi, vibeId) => {
    var encode = ameWagmi.encodeRequestParams(
      [{ name: "vibeId", type: "uint256" }],
      [vibeId]
    );

    var vibeDetail = await ameWagmi.sendGetRequest("getVibe", encode);
    var decode = ameWagmi.decodeResponseData(
      [
        { name: "id", type: "uint256" },
        { name: "detail", type: "string" },
        { name: "address", type: "address" },
      ],
      vibeDetail
    );

    var deocodeVibe = parseVibe([decode[1]])[0];

    return deocodeVibe;
  };

  const getUserInfo = async (profileWagmi, user) => {
    var encodeProfileReq = profileWagmi.encodeRequestParams(
      [{ name: "category", type: "address[]" }],
      [[user]]
    );

    var profileResult = await profileWagmi.sendGetRequest(
      "getUserInfoByAddresses",
      encodeProfileReq
    );
    var decodeProfile = profileWagmi.decodeResponseData(
      [
        { name: "handle", type: "string[]" },
        { name: "nickname", type: "string[]" },
        { name: "avatar", type: "string[]" },
        { name: "profile", type: "string[]" },
      ],
      profileResult
    );

    var parsedProfile = parseProfile(decodeProfile)[0];

    return parsedProfile;
  };

  const updateList = () => {
    setShowModal(false);
    toast.success("Success", {
      duration: 1200,
      position: "top-center",
    });
  };

  const getConnectState = () => {
    if (config != undefined) {
      const account = getAccount(config);
      if (account.address == undefined) {
        return 1;
      } else if (account.chain == undefined) {
        return 2;
      } else {
        return 3;
      }
    }
  };

  const updateComments = () => {
    toast.success("Success", {
      duration: 1200,
      position: "top-center",
    });
    setShowModal(false);
    setTrigger(trigger + 1);
  };

  const setTwitterCard = (_description) => {
    console.log(1111)
    // 设置 Twitter Card 元标签
    // const metaTitle = document.createElement('meta');
    // metaTitle.name = 'twitter:title';
    // metaTitle.content = title;

    const metaDescription = document.createElement("meta");
    metaDescription.name = "twitter:description";
    metaDescription.content = _description;

    document.head.appendChild(metaDescription);

    // const metaImage = document.createElement('meta');
    // metaImage.name = 'twitter:image';
    // metaImage.content = image;

    // // 将元标签添加到头部
    // document.head.appendChild(metaTitle);

    // document.head.appendChild(metaImage);

    // // 清理函数：在组件卸载时移除元标签
    // return () => {
    //     document.head.removeChild(metaTitle);
    //     document.head.removeChild(metaDescription);
    //     document.head.removeChild(metaImage);
    // };
  };




  return vibe != "" ? (
    <div className="VibeDetail">
      
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "#1D1D1D",
              headerBg: "#1D1D1D",
              titleColor: "#fff",
            },
            Input: {
              hoverBg: "#000",
              activeBg: "#000",
            },
          },
          token: {
            colorBgMask: "rgba(0, 0, 0, 0.7)",
            colorIcon: "#fff",
            colorIconHover: "#fff",
            colorTextPlaceholder: "#4B4B4B",
          },
        }}
      >
        <Modal
          style={{
            top: 100,
          }}
          destroyOnClose={true}
          title={modalType}
          open={showModal}
          maskClosable={false}
          footer={null}
          // centered={true}
          onCancel={() => {
            setShowModal(false);
          }}
        >
          {modalType == "Quote" ? (
            <NewVibe
              config={config}
              updateList={updateList}
              resources={quoteInfo}
            ></NewVibe>
          ) : (
            <Comment
              config={config}
              vibeId={vibe.id}
              updateComments={updateComments}
            ></Comment>
          )}
        </Modal>
      </ConfigProvider>

      <div className="VibeDetailHeader">
        <div
          className="VibeDetailHeaderBack"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={BackIcon} width={24} />
          <p>Back</p>
        </div>
      </div>
      <div className="VibeDetailInfo">
        <div className="VibeDetailUser">
          <div
            className="VibeDetailUserAvatarAndHandle"
            onClick={() => {
              navigate("/" + (vibe.handle != "" ? vibe.handle : vibe.user));
            }}
          >
            {vibe.avatar != "" ? (
              <div className="VibeDetailUserAvatar">
                <img src={vibe.avatar} width="100%" />
              </div>
            ) : (
              <Avatar name={vibe.user} variant="beam" size={48} />
            )}

            <div className="VibeDetailUserInfo">
              <div className="VibeDetailUserNickname">
                {vibe.nickname != ""
                  ? vibe.nickname
                  : getShortAddress(vibe.user)}
              </div>

              {vibe.handle != "" ? (
                <div className="VibeDetailUserHandle">@{vibe.handle}</div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <div className="VibeDetailUserDate">{vibe.date}</div>
        </div>

        <div className="VibeDetailVibeType"> {vibe[1]}</div>
      </div>

      <div className="VibeDetailContent">
        <Linkify as="p" options={linkifyOptions}>
          {vibe.content}
        </Linkify>
      </div>
      <div>
        {vibe.resources.hasOwnProperty("images") ? (
          <div className="VibeDetailImage">
            <img src={vibe.resources.images[0]} width="70%" />
          </div>
        ) : (
          <div></div>
        )}

        {vibe.resources.hasOwnProperty("quote") ? (
          <div
            className="VibeDetailQuote"
            onClick={() => {
              navigate("/vibe/" + vibe.resources.quote.id);
            }}
          >
            <Quote detailQuote={quoteDetail}></Quote>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {vibe.categories.length != 0 ? (
        <div className="VibeDetailTags">
          {vibe.categories.map((item, index) => (
            <div
              className="VibeCategory"
              key={index}
              onClick={() => {
                navigate("/tag/" + item);
              }}
            >
              {item}
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}

      <div className="VibeDetailButtons">
        <div
          className="VibeDetailButton"
          onClick={
            getConnectState() == 1
              ? openConnectModal
              : getConnectState() == 2
              ? openChainModal
              : () => {
                  setShowModal(true);
                  setModalType("Quote");
                  setQuoteInfo({
                    id: vibe.id,
                    content: vibe.content,
                  });
                }
          }
        >
          <img src={QuoteIcon} width={24} />
          <div className="VibeDetailButtonTit">Quote</div>
        </div>
        <div
          className="VibeDetailButton"
          onClick={
            getConnectState() == 1
              ? openConnectModal
              : getConnectState() == 2
              ? openChainModal
              : () => {
                  setShowModal(true);
                  setModalType("Comment");
                }
          }
        >
          <img src={CommentIcon} width={24} />
          <div className="VibeDetailButtonTit">Comment</div>
        </div>
        <CopyToClipboard text={window.location}>
          <div
            className="VibeDetailButton"
            onClick={() => {
              toast("Copied", {
                duration: 1200,
                position: "top-center",
              });
            }}
          >
            <img src={CopyLinkIcon} width={24} />
            <div className="VibeDetailButtonTit">Copy Link</div>
          </div>
        </CopyToClipboard>
      </div>

      <div className="BottomLine"></div>

      <Comments config={config} vibeId={vibe.id} trigger={trigger}></Comments>
    </div>
  ) : (
    <div>
      {showLoading ? (
        <div className="ShowVibeDetailLoading">
          <img src={LoadingIcon} width={32} />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
export default VibeDetail;
