import "./Navigator.css"
import { Link } from "react-router-dom";
import VibeIcon from "../../assets/VibeIcon.svg";
import ProfileIcon from "../../assets/ProfileIcon.svg";
function Navigator() {
  return (
    <div>
      <ul className="Navigator">
        <li>
          <Link to={"/"}>
            <img src={VibeIcon} width={24} />
            <p>Vibes</p>
          </Link>
        </li>
        <li>
          <Link to={"/profile"}>
            <img src={ProfileIcon} width={24} />
            <p>Profile</p>
          </Link>
        </li>
      </ul>
    </div>
  );
}
export default Navigator;
