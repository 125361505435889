import React, { useState, useEffect } from "react";
import { Button, Upload, message, ConfigProvider } from "antd";
import uploadimageicon from "../../assets/uploadimage.svg";
import "./ImageUpload.css";
import EditorMenuImage from "../../assets/EditorMenuImage.svg";
import imageCompression from "browser-image-compression";
import AddAvatarIcon from "../../assets/AddAvatarIcon.svg"
import axios from "axios";
function ImageUpload(props) {
  const [imgBuffer, setImgBuffer] = useState([]);
  const [imgBase64, setImgBase64] = useState([props.avatar]);
  const [showLoad, setShowLoad] = useState(false);
  // useEffect(() => {

  //   console.log("props.avatar",props.avatar)

  //   setImgBase64(props.avatar);

  // }, [props.avatar]);

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: props.uploadType == "vibe"?0.1:0.1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      message.error("Upload Fail");
    }
  };

  const upload = async (file) => {
    const limit = file.size / 1024 / 1024 < 10;

    if (limit) {
      const BufferReader = new FileReader();
      const Base64Reader = new FileReader();

      BufferReader.addEventListener("load", function () {
        // setImgBuffer(BufferReader.result);
        // var blob = new Blob([BufferReader.result]);
        //props.changeImageFile(blob);
        //props.changeFileBuffer(BufferReader.result);
        // console.log(props.editorItemIndex)
      });

      Base64Reader.addEventListener("loadend", async function () {
        setImgBase64(Base64Reader.result);
        props.changeImageBase64(Base64Reader.result);
      });

      if (file) {
        BufferReader.readAsArrayBuffer(file);
        Base64Reader.readAsDataURL(file);

        var compressedImage = await compressImage(file);
        props.changeImageFile(compressedImage);
      }
    } else {
      message.error("Image must smaller than 10MB!");
    }

    return limit ? false : Upload.LIST_IGNORE;
  };

  return (
    <div className="ImageUpload">
      <ConfigProvider theme={{ hashed: false }}>
        <Upload
          accept="image/jpeg,image/png,image/gif,image/jpg,image/svg"
          maxCount="1"
          beforeUpload={upload.bind(this)}
          showUploadList={false}
        >
          {props.uploadType == "vibe" ? (
            <div className="uploadVibeImageButton">
              <img src={uploadimageicon} width="24"></img>
            </div>
          ) : (
            <div className="uploadAvatarImageButton">

              <img src={AddAvatarIcon} width={48}/>

              
            </div>
          )}
        </Upload>
      </ConfigProvider>
    </div>
  );
}

export default ImageUpload;
