import { useState, useEffect } from "react";
import "./Profile.scss";
import { useParams, useNavigate } from "react-router-dom";
import { getShortAddress } from "../../libs/Utils";
import Avatar from "boring-avatars";
import Tags from "../../components/Tags/Tags";
import { Modal, ConfigProvider } from "antd";
import { Link } from "react-router-dom";
import config from "../../config/NetworkConfig";
import AmeWagmi from "../../libs/AmeWagmi";
import { getAccount, getEnsAddress, getEnsName } from "@wagmi/core";
import { parseProfile } from "../../libs/Utils";
import { getAddress, isAddress, checksumAddress } from "viem";
import BackIcon from "../../assets/BackIcon.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import UserVibeList from "../../components/UserVibeList/UserVibeList";
import { useConnectModal, useChainModal } from "@rainbow-me/rainbowkit";
import LoadingIcon from "../../assets/Loading.svg";
import Linkify from "linkify-react";
import { normalize } from "viem/ens";
import { useAccount } from "wagmi";
import BaseNameIcon from "../../assets/BaseNameIcon.svg";
import ENSIcon from "../../assets/EnsIcon.svg"
import { getName } from "@coinbase/onchainkit/identity";
import { base } from "viem/chains";

function Profile() {
  const params = useParams();
  const navigate = useNavigate();
  const [showEditorModal, setShowEditorModal] = useState(false);
  const [currentAddress, setCurrentAddress] = useState();
  const [paramsAddress, setParamsAddress] = useState();
  const [profile, setProfile] = useState();
  const { openConnectModal } = useConnectModal();
  const { openChainModal } = useChainModal();

  const [showLoading, setShowLoading] = useState(true);
  const [showConnectWallet, setShowConnectWallet] = useState(false);

  const [showNoUser, setShowNoUser] = useState(false);

  const account = useAccount();

  useEffect(() => {
    (async function fetchData() {
      const ameWagmi = new AmeWagmi(
        config,
        config.chains[0].contracts.VibeProfile
      );

      //visit self
      if (params.handle == undefined) {
        if (account.address == undefined || account.chain == undefined) {
          setShowLoading(false);
          setShowConnectWallet(true);
          setProfile(undefined);
        } else {
          await getUserProfile(ameWagmi, account.address);
          setCurrentAddress(account.address);
          setParamsAddress(checksumAddress(account.address));
        }
      } else if (isAddress(params.handle)) {
        await getUserProfile(ameWagmi, params.handle);

        setCurrentAddress(account.address);
        setParamsAddress(checksumAddress(params.handle));
      } else if (params.handle.endsWith(".base.eth")) {

        const ensAddress = await getEnsAddress(config, {
          name: normalize(params.handle),
          universalResolverAddress:
            "0xC6d566A56A1aFf6508b41f6c90ff131615583BCD",
        });

        if (ensAddress != null) {
          await getUserProfile(ameWagmi, ensAddress);
          setCurrentAddress(account.address);
          setParamsAddress(checksumAddress(ensAddress));
        } else {
          setShowLoading(false);
          setShowNoUser(true);
          setProfile(undefined);
        }
      } else {
        var paramsAddress = await getAddressByHandle(ameWagmi, params.handle);

        if (paramsAddress != "0x0000000000000000000000000000000000000000") {
          await getUserProfile(ameWagmi, paramsAddress);
          setCurrentAddress(account.address);
          setParamsAddress(checksumAddress(paramsAddress));
        } else {
          setShowLoading(false);
          setShowNoUser(true);
          setProfile(undefined);
        }
      }
    })();
  }, [params, account.address, account.chain]);

  const getUserProfile = async (ameWagmi, userAddress) => {
    var encode = ameWagmi.encodeRequestParams(
      [{ name: "address", type: "address[]" }],
      [[userAddress]]
    );

    var returnValue = await ameWagmi.sendGetRequest(
      "getUserInfoByAddresses",
      encode
    );
    var decode = ameWagmi.decodeResponseData(
      [
        { name: "handle", type: "string[]" },
        { name: "nickname", type: "string[]" },
        { name: "avatar", type: "string[]" },
        { name: "profile", type: "string[]" },
      ],
      returnValue
    );

    var decodeProfile = parseProfile(decode)[0];

    const baseName = await getName({
      address: userAddress,
      chain: base,
    });

    console.log("baseName",baseName)

    decodeProfile.baseName = baseName;

    setProfile(decodeProfile);

    return decodeProfile;
  };

  const getAddressByHandle = async (ameWagmi, handle) => {
    var encode = ameWagmi.encodeRequestParams(
      [{ name: "handle", type: "string" }],
      [[handle]]
    );
    var returnValue = await ameWagmi.sendGetRequest(
      "getAddressByHandle",
      encode
    );
    var decode = ameWagmi.decodeResponseData(
      [{ name: "user", type: "address" }],
      returnValue
    );

    return decode[0];
  };

  const getConnectState = () => {
    if (config != undefined) {
      const account = getAccount(config);
      if (account.address == undefined) {
        return 1;
      } else if (account.chain == undefined) {
        return 2;
      } else {
        return 3;
      }
    }
  };

  const linkifyOptions = {
    defaultProtocol: "https",
    target: "_blank",
    attributes: {
      onClick: (event) => {
        event.stopPropagation();
      },
    },
  };

  return (
    <div>
      <Toaster />
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "#1D1D1D",
              headerBg: "#1D1D1D",
              titleColor: "#fff",
            },
            Input: {
              hoverBg: "#000",
              activeBg: "#000",
            },
          },
          token: {
            colorBgMask: "rgba(0, 0, 0, 0.7)",
            colorIcon: "#fff",
            colorIconHover: "#fff",
            colorTextPlaceholder: "#4B4B4B",
          },
        }}
      >
        <Modal
          destroyOnClose={true}
          title="Edit Profile"
          open={showEditorModal}
          maskClosable={false}
          footer={null}
          centered={true}
          onCancel={() => {
            setShowEditorModal(false);
          }}
        ></Modal>
      </ConfigProvider>

      {profile != undefined ? (
        <div>
          <div className="UserProfile">
            {params.handle != undefined ? (
              <div
                className="UserProfileBack"
                onClick={() => {
                  // 
                  if(window.history.state.idx>0){
                    navigate(-1);
                  }else{
                    navigate('/', { replace: true });
                  }
                  
                }}
              >
                <img src={BackIcon} width={24} />
                <p>Back</p>
              </div>
            ) : (
              <div></div>
            )}

            <div className="UserProfileHeader">
              <div className="UserInfoUnregistered">
                {profile.avatar != "" ? (
                  <div className="UserProfileAvatar">
                    <img src={profile.avatar} width="100%" />
                  </div>
                ) : (
                  <Avatar
                    name={
                      paramsAddress != undefined
                        ? getAddress(paramsAddress)
                        : ""
                    }
                    variant="beam"
                    size={48}
                  />
                )}

                <div className="UserInfo">
                  {profile.nickname != "" ? (
                    <div className="UserInfoNickname">{profile.nickname}</div>
                  ) : (
                    <div className="UserInfoAddress">
                      {getShortAddress(paramsAddress)}
                    </div>
                  )}

                  {profile.handle != "" ? (
                    <div className="UserInfoHandle">@{profile.handle}</div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>

              {paramsAddress == currentAddress ? (
                <div className="UserProfileButtons">
                  <Link to={"/settings"}>
                    <div className="Editor" onClick={() => {}}>
                      Edit Profile
                    </div>
                  </Link>

                  <CopyToClipboard text={window.location}>
                    <div
                      className="CopyLink"
                      onClick={() => {
                        toast("Copied", {
                          duration: 1200,
                          position: "top-center",
                        });
                      }}
                    >
                      Copy Link
                    </div>
                  </CopyToClipboard>
                </div>
              ) : (
                <div></div>
              )}
            </div>





            {profile.baseName != null ? (
              <a
                className="Basename"
                href={profile.baseName.endsWith(".base.eth")?"https://www.base.org/name/"+ profile.baseName:"https://app.ens.domains/" + profile.baseName}
                target="_blank"
              >
                <div className="BasenameIcon">
                  <img src={profile.baseName.endsWith(".base.eth")?BaseNameIcon:ENSIcon} width="100%" />
                </div>
                <div className="BasenameValue">{profile.baseName}</div>
              </a>
            ) : (
              <div></div>
            )}

            <div className="UserMoreInfo">
              {profile.profile.bio != "" ? (
                <div className="UserBio">
                  <Linkify as="p" options={linkifyOptions}>
                    {profile.profile.bio}
                  </Linkify>
                </div>
              ) : (
                <div></div>
              )}

              {profile.profile.tags.length != 0 ? (
                <div className="UserTags">
                  <Tags
                    changeTags={() => {}}
                    myTags={
                      profile.profile.tags == undefined
                        ? []
                        : profile.profile.tags
                    }
                    edit={false}
                    max={3}
                  ></Tags>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <div className="UserVibeListTitle">Vibes</div>
          <UserVibeList config={config} type={paramsAddress}></UserVibeList>
        </div>
      ) : (
        <div>
          {showLoading ? (
            <div className="ShowProfileLoading">
              <img src={LoadingIcon} width={32} />
            </div>
          ) : (
            <div></div>
          )}

          {showNoUser ? (
            <div className="ProfileNoUser">The user does not exist</div>
          ) : (
            <div></div>
          )}

          {showConnectWallet ? (
            <div className="ProfileConnectWallet">
              <div className="ProfileConnectWalletTitle">
                Please connect your wallet
              </div>
              <div
                className="ProfileConnectWalletButton"
                onClick={
                  getConnectState() == 1
                    ? openConnectModal
                    : getConnectState() == 2
                    ? openChainModal
                    : () => {}
                }
              >
                Connect Wallet
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
}

export default Profile;
