import { useEffect, useState } from "react";
import Dotdotdot from "react-dotdotdot";
import Avatar from "boring-avatars";
import { getShortAddress } from "../../libs/Utils";
import NewQuoteIcon from "../../assets/NewQuoteIcon.svg";
import "./Quote.css";
function Quote(props) {
  const [shortQuote, setShortQuote] = useState("");

  const [detailQuote, setDetailQuote] = useState("");

  useEffect(() => {
    if (props.shortQuote != undefined) {
      setShortQuote(props.shortQuote);
    }

    if (props.detailQuote != undefined) {
      setDetailQuote(props.detailQuote);
    }
  }, [props.shortQuote, props.detailQuote]);

  return (
    <div>
      {shortQuote != "" ? (
        <div className="ShortQuote">
  
          <div className="ShortQuoteDot">
            {shortQuote}
            {/* <Dotdotdot clamp={3}>{}</Dotdotdot> */}
          </div>
          <img src={NewQuoteIcon} width={18} />

        </div>
      ) : (
        <div></div>
      )}
      {detailQuote != "" ? (
        <div className="DetailQuote">
          <div className="QuoteUser">
            {detailQuote.avatar != "" ? (
              <div className="QuoteUserAvatar">
                <img src={detailQuote.avatar} width="100%" />
              </div>
            ) : (
              <Avatar name={detailQuote.user} variant="beam" size={32} />
            )}

            <div className="QuoteUserName">
              {detailQuote.nickname != ""
                ? detailQuote.nickname
                : getShortAddress(detailQuote.user)}
            </div>

            {detailQuote.handle != "" ? (
              <div className="QuoteUserHandle">@{detailQuote.handle}</div>
            ) : (
              <div></div>
            )}

            <div className="QuoteUserDate">{detailQuote.date}</div>
          </div>
          <div className="QuoteUserContent">
            <div>
              <Dotdotdot clamp={3}>{detailQuote.content}</Dotdotdot>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
export default Quote;
