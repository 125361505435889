import {create} from 'zustand';
const initialVibeListState = {
    vibes: [], 
    pages:{
        pageNum: 1,
        pageSize: 10,
        showLoadMore:false
    },
    vibeType: "All", 
    vibeListType: "", 
}
export const useVibeList = create((set) => ({
    ...initialVibeListState,
    setVibes: (newVibes) => set(() => ({ vibes:newVibes })),
    setPages:(newPages)=> set((state)=>({
        pages: {
            ...state.pages,
            ...newPages,
        }, 
    })),
    setVibeType: (newVibeType) => set(() => ({ vibeType:newVibeType })),
    setVibeListType: (newVibeListType) => set(() => ({ vibeListType:newVibeListType })),
    reset:()=>{
        set(initialVibeListState)
    }
}));

const initialUserListState = {
    vibes: [], 
    pages:{
        pageNum: 1,
        pageSize: 4,
        showLoadMore:false
    },
    vibeType: "All", 
    vibeListType: "", 
}
export const useUserVibeList = create((set) => ({
    ...initialUserListState,
    setVibes: (newVibes) => set(() => ({ vibes:newVibes })),
    setPages:(newPages)=> set((state)=>({
        pages: {
            ...state.pages,
            ...newPages,
        }, 
    })),
    setVibeType: (newVibeType) => set(() => ({ vibeType:newVibeType })),
    setVibeListType: (newVibeListType) => set(() => ({ vibeListType:newVibeListType })),
    reset:()=>{
        set(initialUserListState)
    }
}));



