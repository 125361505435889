import { useState, useEffect } from "react";
import AmeWagmi from "../../libs/AmeWagmi";
import { useNavigate } from "react-router-dom";
import Avatar from "boring-avatars";
import "./Comments.css";
import { parseComments, getShortAddress, parseProfile } from "../../libs/Utils";
import Linkify from "linkify-react";
function Comments(props) {
  const navigate = useNavigate();

  const [pages, setPages] = useState({
    pageNum: 1,
    pageSize: 3,
  });

  const [comments, setComments] = useState([]);
  const [ameWagmi, setAmeWagmi] = useState();
  const [profileWagmi, setProfileWagmi] = useState();
  const [showLoadMore, setShowLoadMore] = useState(true);

  const linkifyOptions = {
    defaultProtocol: "https",
    target: "_blank",
    attributes: {
      onClick: (event) => {
        event.stopPropagation();
      },
    },
  };

  useEffect(() => {
    (async function fetchData() {
      const ameWagmi = new AmeWagmi(
        props.config,
        props.config.chains[0].contracts.VibeComment
      );

      const profileWagmi = new AmeWagmi(
        props.config,
        props.config.chains[0].contracts.VibeProfile
      );

      getComments(ameWagmi, profileWagmi, pages.pageNum, pages.pageSize);

      setAmeWagmi(ameWagmi);
      setProfileWagmi(profileWagmi);
    })();
  }, [props.vibeId, props.trigger]);

  const getComments = async (ameWagmi, profileWagmi, pageNum, pageSize) => {
    var encode = ameWagmi.encodeRequestParams(
      [
        { name: "vibeId", type: "uint256" },
        { name: "pageNum", type: "uint256" },
        { name: "pageSize", type: "uint256" },
      ],
      [props.vibeId, pageNum, pageSize]
    );

    var commentsEncode = await ameWagmi.sendGetRequest("getComments", encode);

    var commentDecode = ameWagmi.decodeResponseData(
      [{ name: "comments", type: "string[]" }],
      commentsEncode
    );

    var parsedComent = parseComments(commentDecode[0]);

    const users = parsedComent.map((item) => item.user);

    const parsedProfile = await getUserInfo(profileWagmi, users);

    const newComments = parsedComent.map((item, index) => {
      return { ...item, ...parsedProfile[index] };
    });


    if (pageNum == 1) {
      setComments(newComments);
    } else {
      setComments([...comments, ...newComments]);
    }

    if (newComments.length < pageSize) {
      setShowLoadMore(false);
    } else {
      setShowLoadMore(true);
    }

    setPages((pages) => ({
      ...pages,
      pageNum: pageNum,
    }));
  };

  const loadMore = async () => {
    var newPageNum = pages.pageNum + 1;
    setPages((pages) => ({
      ...pages,
      pageNum: newPageNum,
    }));

    await getComments(ameWagmi, profileWagmi, newPageNum, pages.pageSize);
  };

  const getUserInfo = async (profileWagmi, users) => {
    var encodeProfileReq = profileWagmi.encodeRequestParams(
      [{ name: "category", type: "address[]" }],
      [users]
    );

    var profileResult = await profileWagmi.sendGetRequest(
      "getUserInfoByAddresses",
      encodeProfileReq
    );
    var decodeProfile = profileWagmi.decodeResponseData(
      [
        { name: "handle", type: "string[]" },
        { name: "nickname", type: "string[]" },
        { name: "avatar", type: "string[]" },
        { name: "profile", type: "string[]" },
      ],
      profileResult
    );

    var parsedProfile = parseProfile(decodeProfile);
    return parsedProfile;
  };

  return (
    <div>
      <div className="CommentTitle">Comments</div>
      {comments.length != 0 ? (
        <ul className="CommentList">
          {comments.map((item, index) => (
            <li key={index}>
              <div
                className="CommentUser"
                onClick={() => {
                  navigate("/" + (item.handle != "" ? item.handle : item.user));
                }}
              >
                {item.avatar != "" ? (
                  <div className="CommentUserAvatar">
                    <img src={item.avatar} width="100%" />
                  </div>
                ) : (
                  <Avatar name={item.user} variant="beam" size={32} />
                )}

                <div className="CommentUserName">
                  {item.nickname != ""
                    ? item.nickname
                    : getShortAddress(item.user)}
                </div>

                {item.handle != "" ? (
                  <div className="CommentUserHandle">@{item.handle}</div>
                ) : (
                  <div></div>
                )}

                <div className="CommentUserDate">{item.date}</div>
              </div>

              <div className="CommentContent">
                <Linkify as="p" options={linkifyOptions}>
                  {item.comment}
                </Linkify>
              </div>
            </li>
          ))}

          {showLoadMore ? (
            <div className="CommentsLoadMore" onClick={loadMore}>
              Load More
            </div>
          ) : (
            <div></div>
          )}
        </ul>
      ) : (
        <div className="NoComment">No Comments</div>
      )}
    </div>
  );
}
export default Comments;
