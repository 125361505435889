import { useState, useEffect } from "react";
import EmojiIcon from "../../assets/EmojiIcon.svg"
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "./Emoji.scss";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
function Emoji(prop) {
  return (
    <div>
      <div >


        <Menu menuButton={<div className="EmojiButton">
            <img src={EmojiIcon} />
        </div>} direction="bottom">
        <Picker data={data} theme="dark" onEmojiSelect={prop.emojiChange} maxFrequentRows={2} perLine={7}
 previewPosition="none" searchPosition="none"/>
        
        </Menu>

      </div>
    </div>
  );
}
export default Emoji;
