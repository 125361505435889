import Vibe from "./components/Vibe/Vibe";
import Badges from "./pages/Badges/Badges";
import VibeDetail from "./pages/VibeDetail/VibeDetail";
import Profile from "./pages/Profile/Profile";
import ProfileEditor from "./pages/ProfileEditor/ProfileEditor";
const routes = [
  {
    path: "/",
    element: <Vibe />,
  },
  {
    path: "/tag/:tag",
    element: <Vibe />,
  },
  {
    path: "/:handle",
    element: <Profile />,
  },
  {
    path: "/Badges",
    element: <Badges />,
  },
  {
    path: "/Vibe/:vibeId",
    element: <VibeDetail />,
  },
  {
    path: "/Profile",
    element: <Profile />,
  },
  {
    path: "/Settings",
    element: <ProfileEditor />,
  },
];
export default routes;
