import { useState } from "react";
import "./MobileHeader.css";
import { Drawer } from "antd";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Navigator from "../Navigator/Navigator";
import Logo from "../../assets/logo.svg";
import MenuIcon from "../../assets/MenuIcon.svg";
import { Link } from "react-router-dom";
function MobileHeader() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <div className="MobileHeaderBg">
      <div className="MobileHeader">
        <Drawer
          width="70%"
          closable={false}
          onClose={() => {
            setShowMobileMenu(false);
          }}
          open={showMobileMenu}
        >
          <Navigator></Navigator>
        </Drawer>

          <Link to={"/"} className="MobileHeaderLogo">
            <img src={Logo} width="100%" />
          </Link>

        <div className="MobileHeadRight">
          <ConnectButton
            chainStatus="name"
            showBalance={{
              smallScreen: false,
              largeScreen: false,
            }}
            label="Connect Wallet"
          />
          <div
            className="MobileMenuButton"
            onClick={() => {
              setShowMobileMenu(true);
            }}
          >
            <img src={MenuIcon} width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
